div.order-full-cell{
  flex: 100%;
}

div.order-half-cell{
  flex: 50%;
}

div.order-quarter-cell{
  flex: 25%;
}

div.order-third-cell{
  flex: 33%;
}

div.order-eighth-cell{
  flex: 12.5%;
}

div.info-block{
  display: flex;
  flex-flow: column nowrap;
  padding: 10px;
}

div.info-block div{
  margin-bottom: 10px;
}

.new{
  font-weight: bold;
  color: black;
}

.in-progress{
  color: green;
  font-weight: bold;
}

.completed{
  font-weight:normal;
  color: black;
}

.waiting-for-information{
  font-weight: bold;
  color: orange;
}

.waiting-for-payment{
  font-weight: bold;
  color: red;
}

.left{
  text-align: left;
}

.right{
  text-align: right;
}

.center{
  text-align: center;
}